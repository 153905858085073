import { createFeatureSelector } from '@ngrx/store';
import { ILayoutFeatureState, ILayoutSharedState } from './interfaces';

/**
 * Create Feature Selectors based on the above selectors
 */

export const getUiState = (state: ILayoutFeatureState & ILayoutSharedState) => state.uiState;

export const selectLayoutState =
  createFeatureSelector<ILayoutFeatureState & ILayoutSharedState>('layout');
